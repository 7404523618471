.navbody {
  background-color: black;
}

.navbar-brand {
  display: inline-block;
}

.logo {
  padding-left: 12px;
}

.greeting {
  display: flex;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  padding-top: 45px;
  padding-bottom: 24px;
  justify-content: center;
}
.items {
  display: flex;
  justify-content: space-evenly;
  padding-top: 24px;
}

.card {
  height: 528px;
}

.cimg1 {
  border: 4px;
  border-style: inset;
  border-color: red;
  width: auto;
  height: 290px;
}

.cimg2 {
  border: 4px;
  border-style: inset;
  border-color: greenyellow;
  width: auto;
  height: 290px;
}

.cimg3 {
  border: 4px;
  border-style: inset;
  border-color: rgb(0, 110, 255);
  width: auto;
  height: 290px;
}

.ctitle1 {
  font-family: Impact, 'Arial Narrow Bold', sans-serif;
  font-weight: 200;
  color: red;
}

.ctitle2 {
  font-family: Impact, 'Arial Narrow Bold', sans-serif;
  font-weight: 200;
  color: greenyellow;
}

.ctitle3 {
  font-family: Impact, 'Arial Narrow Bold', sans-serif;
  font-weight: 200;
  color: rgb(0, 110, 255);
}


.cbody {
  background-color: black;
}

.ctext {
  color: rgb(246, 239, 241)
}